(function( $ ) {

    $.fn.commonSlider = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var sliderContainer = jqContainer.find('.common-slider-container');
            if (!sliderContainer || sliderContainer === undefined) {
                console.error('common-slider: no common-slider-container fround in', this);
            }
            var prev = jqContainer.find('.prev');
            var next = jqContainer.find('.next');
            var nav = jqContainer.find('.nav');
            var show = jqContainer.data('show') ? jqContainer.data('show') : 4;
            var showMD = jqContainer.data('showMd') ? jqContainer.data('showMd') : 3;
            var showXS = jqContainer.data('showXs') ? jqContainer.data('showXs') : 1;
            var scroll = jqContainer.data('scroll') ? jqContainer.data('scroll') : 1;
            var arrows = jqContainer.data('arrows') ? jqContainer.data('arrows') : false;

            var arrowsMD = jqContainer.data('arrowsMd') ? qContainer.data('arrowsMd') : false;
            var arrowsXS = jqContainer.data('arrowsXs') ? jqContainer.data('arrowsXs') : false;
            var dots = jqContainer.data('dots') ? jqContainer.data('dots') : false;
            var dotsMD = jqContainer.data('dotsMd') ? jqContainer.data('dotsMd') : false;
            var dotsXS = jqContainer.data('dotsXs') ? jqContainer.data('dotsXs') : false;
            var infinite = jqContainer.data('infinite') ? jqContainer.data('infinite') : false;
            var autoplay = jqContainer.data('autoplay') ? jqContainer.data('autoplay') : false;
            var autoplaySpeed = jqContainer.data('autoplaySpeed') ? jqContainer.data('autoplaySpeed') : 2000;
            var adaptiveHeight = jqContainer.data('adaptiveHeight') ? jqContainer.data('adaptiveHeight') : false;
            var fade = jqContainer.data('fade') ? jqContainer.data('fade') : false;
            sliderContainer.slick({
                slidesToShow: show,
                slidesToScroll: scroll,
                dots: dots,
                arrows: arrows,
                infinite: infinite,
                prevArrow: prev,
                nextArrow: next,
                appendDots: nav,
                autoplay: autoplay,
                autoplaySpeed: autoplaySpeed,
                adaptiveHeight: adaptiveHeight,
                fade: fade,
                speed: 500,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: showMD,
                            arrows: arrowsMD,
                            dots: dotsMD,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: showXS,
                            arrows: arrowsXS,
                            dots: dotsXS,
                        }
                    },
                ]
            });
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {

    $('.common-slider').commonSlider();

});
