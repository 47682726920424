import PerfectScrollbar from 'perfect-scrollbar';
import perfectScrollbarStyles from '../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';

(function( $ ) {

    $.fn.customScroll = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            const ps = new PerfectScrollbar(this);
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.custom-scroll').customScroll();
});
