let commons = require('../utils/commons');

function detailTabs(tabNode) {
    // elements
    this.baseNode = tabNode;
    this.tabsControlls = this.baseNode.getElementsByClassName('tab-selector')[0];
    if (this.tabsControlls === undefined || !this.tabsControlls) {
        console.log('detailTabs: no tabsCpntrolls found by class "tab-selector"');
        return false;
    }
    this.tabsLinks = this.tabsControlls.getElementsByTagName('a');
    this.tabsContent = this.baseNode.getElementsByClassName('tab');
    // functions
    this.unSelectAll = function() {
        for(let i = 0; i < this.tabsLinks.length; i++) {
           let tabsLinkNode = this.tabsLinks.item(i);
           commons.removeClassName(tabsLinkNode, 'current');
        }
        for(let i = 0; i < this.tabsContent.length; i++) {
           let tabContentNode = this.tabsContent.item(i);
           commons.removeClassName(tabContentNode, 'current');
        }
        for(let i = 0; i < this.tabsLinks.length; i++) {
           let tabsLinkNode = this.tabsLinks.item(i);
           let tabsContentId = tabsLinkNode.getAttribute("href");
           tabsContentId = tabsContentId.substring(1); // remove #
           commons.removeClassName(this.tabsControlls, tabsContentId);
        }
    }
    this.selectTab = function(tabsLinkNode) {
        commons.addClassName(tabsLinkNode, 'current');
        let tabsContentId = tabsLinkNode.getAttribute("href");
        tabsContentId = tabsContentId.substring(1); // remove #
        const tabContentNode = document.getElementById(tabsContentId);
        commons.addClassName(tabContentNode, 'animated');
        commons.addClassName(this.tabsControlls, tabsContentId);
        setTimeout(function() {
            commons.removeClassName(tabContentNode, 'animated');
            commons.addClassName(tabContentNode, 'current');
        }, 100);
    }
    // events
    for(let i = 0; i < this.tabsLinks.length; i++) {
       let tabsLinkNode = this.tabsLinks.item(i);
       tabsLinkNode.addEventListener('click', function(e) {
           e.preventDefault();
           this.unSelectAll();
           this.selectTab(tabsLinkNode);
       }.bind(this));
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const customTabsContainers = document.getElementsByClassName('custom-tabs');
    for(let i = 0; i < customTabsContainers.length; i++) {
       let customTabsContainer = customTabsContainers.item(i);
       const detailTabsObj = new detailTabs(customTabsContainer);
    }
});
