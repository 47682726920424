(function( $ ) {

    $.fn.mobileSubMenu = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            const jqContainer = $(this);
            const listIndexHaveSubMenu = jqContainer.find('li.have-sub-menu');
            listIndexHaveSubMenu.each(function() {
                const jqListIndex = $(this);
                var  openSubMenuLink = jqListIndex.find('>a')[0];
                if (!openSubMenuLink) {
                    console.error('mobileSubMenu: openSubMenuLink not found in ', this);
                    return false;
                }
                openSubMenuLink = $(openSubMenuLink);
                var returnSubMenuLink = jqListIndex.find('.return>a')[0];
                if (!returnSubMenuLink) {
                    console.error('mobileSubMenu: returnSubMenuLink not found in ', this);
                    return false;
                }
                returnSubMenuLink = $(returnSubMenuLink)
                var subMenuContainer = jqListIndex.find('.sub-menu')[0];
                if (!subMenuContainer) {
                    console.error('mobileSubMenu: subMenuContainer not found in ', this);
                    return false;
                }
                subMenuContainer = $(subMenuContainer)

                openSubMenuLink.click(function(e) {
                    e.preventDefault();
                    subMenuContainer.addClass('open');
                });
                returnSubMenuLink.click(function(e) {
                    e.preventDefault();
                    subMenuContainer.removeClass('open');
                });

            });
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-sub-menu').mobileSubMenu();
});
